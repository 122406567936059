<template>
  <div class="notification-send">
    <form-wizard
      color="#F8AC18"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Enviar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="vertical-steps vue-form-wizard-notification vertical-steps-notification  steps-transparent mb-3"
      @on-complete="validationForm">
      <!-- data notification -->
      <tab-content title="Dados da Notificação" icon="feather icon-file-text">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Dados da Notificação</h5>
          </b-col>
          <b-col md="8">
            <b-form-group label="Título" label-for="titulo">
              <b-form-input
                id="titulo"
                v-model="notificationData.notificationName"
                name="notificationName"
                type="text"
                placeholder="Título"
                disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Tipo da Mensagem" label-for="tipo-mensagem">
              <v-select
                id="tipo-mensagem"
                v-model="notificationData.severity"
                name="tipo-mensagem"
                label="title"
                :options="option_severity"
                disabled />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Texto da Mensagem" label-for="texto-mensagem">
              <b-form-textarea
                id="texto-mensagem"
                v-model="notificationData.data.message"
                name="title"
                type="text"
                placeholder="Texto da Mensagem"
                disabled />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Imóvel" label-for="imovel">
              <b-form-input
                id="imovel"
                v-model="notificationData.propertyCode"
                name="propertyCode"
                type="text"
                placeholder="Imóvel"
                disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="my-2">
          </b-col>
        </b-row>
      </tab-content>

      <!-- anexos -->
      <tab-content title="Anexos" icon="feather icon-user">
        <b-row>
          <b-col md="12">
            <ul v-if="attachments.length" class="detailing__grid">
              <li
                v-for="(attachment, key) in attachments"
                :key="key"
                class="detailing__document-card">
                <div>
                  <b-modal
                    :id="attachment.notificationId"
                    ok-only
                    ok-variant="secondary"
                    ok-title="Fechar"
                    modal-class="modal-secondary"
                    centered
                    :title="attachment.description">
                    <b-card-text class="text-center detailing__dp-center">
                      <img width="100%"
                        :src="attachment.dataType + ',' + attachment.binary"
                        alt="Image 1')">
                    </b-card-text>
                  </b-modal>
                  <b-link v-if="attachment.dataType.includes('application/pdf')"
                    :to="{ name: 'company-view-file' }" target="_blank" 
                    class="detailing__cursor"
                    @click="setDataFile({ id: attachment.binaryObjectId, data: {dataType: attachment.dataType, bytes: attachment.binary }})">
                    <img
                      class="mt-2"
                      src="~@/assets/images/icons/doc.png"
                      alt="document icon">
                  </b-link>
                  <b-link v-else
                    class="detailing__cursor"
                    @click="$bvModal.show(attachment.notificationId)">
                    <img
                      class="mt-2"
                      src="~@/assets/images/icons/doc.png"
                      alt="document icon">
                  </b-link>
                </div>
                <h5 class="mt-2">{{ attachment.description }}</h5>
              </li>
            </ul>
            <ul v-else class="detailing__grid">
              <li class="text-center notification-send__li">
                Não existe anexo
              </li>
            </ul>
          </b-col>
        </b-row>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </tab-content>

      <!-- data send -->
      <tab-content title="Envio" icon="feather icon-file-text">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Dados do Envio</h5>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="data-envio" label="Data de Envio">
              <validation-observer
                ref="registerForm"
                #default="{ errors }"
                name="data-criacao"
                rules="required">
                <b-form-input
                  id="date-publish"
                  v-model="notificationSubmissionsData.submissionDate"
                  v-mask="'##/##/####'"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-observer>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Perfis Alvos" label-for="perfis-alvos">
              <v-select
                id="perfis-alvos"
                v-model="notificationSubmissionsData.profiles"
                name="perfis-alvos"
                multiple
                label="title"
                :options="option_profile" />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-checkbox
              id="send-email"
              v-model="notificationSubmissionsData.sendEmail"
              :checked="renot"
              name="send-email"
              switch
              inline>
              <span>Enviar e-mail</span>
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  VBModal,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies,import/extensions,no-unused-vars
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import Moment from 'moment/moment'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BLink,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    Moment,
  },
  directives: {
    Portuguese,
    'b-modal': VBModal,
  },
  data() {
    return {
      id: this.$route.params.notification,
      config: {
        enableTime: false,
        dateFormat: 'd-m-Y',
        // dateFormat: 'd-m-Y H:i',
        locale: Portuguese,
      },
      inputs: [
        {
          description: '',
          bytes: '',
        },
      ],
      notificationData: {
        notificationName: null,
        data: {
          message: null,
        },
        creationTime: Moment(new Date()).format('DD-MM-YYYY hh:mm'),
        propertyCode: null,
        submissionDate: null,
        sendEmail: true,
        severity: 0,
        profiles: [],
      },

      notificationFilesData: {
        binaryObject: {
          bytes: 'string',
          description: 'string',
        },
      },
      attachments: [],
      renot: true,
      notificationSubmissionsData: {
        submissionDate: null,
        sendEmail: true,
        profiles: null,
      },

      option_profile: [],
      option_severity: [
        {
          title: 'Informativa',
          value: 0,
        },
        {
          title: 'Sucesso',
          value: 1,
        },
        {
          title: 'Atenção',
          value: 2,
        },
      ],
      endpointNotification: '/api/services/app/Notification/GetNotification',
      endpointNotificationFiles: '/api/services/app/NotificationFiles/GetNotificationFileForEdit',
      endpointNotificationSubmissions: '/api/services/app/NotificationSubmissions/CreateOrEdit',
    }
  },
  beforeMount() {
    if (!this.$route.params.notification) {
      this.$router.push({ name: 'notification-list' })
    }
  },
  async mounted() {
    this.listRoles()
    const responseNotification = await this.$http.get(
      `/api/services/app/Notification/GetNotification?Id=${this.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.notificationData.notificationName = responseNotification.data.result.notificationName
    this.notificationData.data.message = responseNotification.data.result.message
    this.notificationData.notificationName = responseNotification.data.result.notificationName
    this.notificationData.severity = this.option_severity.filter(s => s.value === responseNotification.data.result.severity) 
    this.attachments = responseNotification.data.result.files
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    setDataFile(data) {
      localStorage.setItem('fileDataView', JSON.stringify(data))
    }, 
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.register()
        }
      })
    },
    async register() {
      try {
        if (this.id) {
          this.notificationSubmissions(this.id)
          this.msgSuccess()
          setTimeout(() => {
            this.$router.push({ name: 'notification-list' })
          }, 2700)
        }
      } catch (error) {
        this.msgError()
      }
    },

    async notificationSubmissions(data) {
      try {
        const profileTypes = []
        this.notificationSubmissionsData.profiles.forEach(element => {
          profileTypes.push(element)
        })

        const n = {
          submissionDate: this.notificationSubmissionsData.submissionDate,
          sendEmail: this.notificationSubmissionsData.sendEmail,
          notificationId: data,
          profiles: profileTypes,
        }

        await this.$http.post(this.endpointNotificationSubmissions, n, {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        })
      } catch (error) {
        this.errors = error.message
      }
    },

    handleFileSelect(evt, callback) {
      this.file = evt
      const reader = new FileReader()
      reader.onload = callback
      reader.addEventListener(
        'load',
        () => {
          this.html = reader.result
        },
        false,
      )
      reader.readAsDataURL(this.file)
    },
    clearFiles() {
      this.$refs['file-input'].reset()
    },
    addRow() {
      this.inputs.push({
        description: '',
        bytes: '',
      })
    },
    deleteRow(index) {
      this.inputs.splice(index, 1)
    },
    async listRoles() {
      const resRoles = await this.$http.post(
        '/api/services/app/Role/GetRoles',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )

      this.option_profile = resRoles.data.result.items.map(res => ({
        value: res.id,
        title: res.displayName,
      }))
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados gravados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import 'vue-select/src/scss/vue-select.scss';
@import './../sass/_variables.scss';
body {
    background-color: #f8f8f8 !important;
}

.notification-send {
  // display: flex;
  max-width: 100%;
  &__li {
    list-style-type: none;
  }

  .vue-form-wizard .clearfix:after {
    display: none;
  }

  .vertical-steps.vue-form-wizard .wizard-card-footer {
    display: flex;
    justify-content: space-between;
    float: none;
  }

  .vertical-steps.vue-form-wizard .wizard-tab-content,
  .vertical-steps.vue-form-wizard .wizard-card-footer {
    // width: 100%;
    margin-left: 0;
  }
  li.active a {
    background-color: #fff6e8 !important;
  }
  .wizard-icon-container {
    background-color: rgb(248 172 24 / 0%) !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
    box-shadow: 0 3px 6px 0 rgb(105 108 255 / 0%) !important;
  }
  .vue-form-wizard
    .wizard-navigation
    .wizard-nav
    li
    .wizard-icon-circle.checked {
    background-color: rgb(248 172 24 / 0%) !important;
  }
  .vue-form-wizard
    .wizard-navigation
    .wizard-nav
    li.active
    a
    .wizard-icon-circle
    .wizard-icon-container
    .wizard-icon {
    color: #f8ac18 !important;
    font-size: 1.3rem !important;
  }
  .stepTitle {
    padding-right: 12px !important;
  }
  .active {
    border-color: #fff6e800 !important;
    box-shadow: 0 4px 18px -4px #ffffff00 !important;
  }
  .wizard-icon {
    color: #5e5873 !important;
  }
  div.wizard-navigation li.active a span {
    color: #f8af26 !important;
    font-weight: 500 !important;
  }
  div.wizard-navigation li a span {
    color: #5e5873 !important;
    font-weight: 500 !important;
  }

  .wizard-icon-circle {
    margin-right: 0px !important;
  }
  .vertical-steps.vue-form-wizard .wizard-navigation .wizard-nav {
    padding-top: 0px !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 0.2rem !important;
  }
  .vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
    border: none;
    background-color: #ededed00 !important;
    border-radius: 6px;
  }

//---------- custon ----------//
.vue-form-wizard-notification {
  background-color: #f8f8f8 !important;
  .wizard-navigation {
    .wizard-nav {
      padding: 0px 25px 0px 0px !important;
      li {
        padding-bottom: 0rem !important;
        .wizard-icon-circle {
          background-color: #f8f8f8 !important;
        }
        &.active {
          .wizard-icon-container {
            background-color: rgb(255 245 230) !important;
            .wizard-icon {
              font-size: 1.3rem !important;
              color: #ffb200 !important;
            }
          }
        }
      }
    }
  }
}
//---------- custon ----------//
.vertical-steps-notification {
  &.vue-form-wizard {
    .wizard-card-footer {
      margin-left: 201px !important;
    }
  }
}
  .detailing {
    box-sizing: border-box;
    padding: 10px 20px;

    & span,
    & td {
      color: $color-black;
    }

    &__ft-size-10 {
      font-size: 10px;
    }
    &__ft-size-14 {
      font-size: 14px;
    }

    &__title {
      font-family: $source-sans-pro;
      color: $color-black;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    &__grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 900px) {
        flex-direction: row;
      }
    }

    &__document-card {
      box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
      width: 150px;
      border-radius: 9px;
      box-sizing: border-box;
      padding: 10px;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: 900px) {
        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }

    &__feedback-description {
      font-size: 16px;
      line-height: 19px;
      color: #a6adba;
      width: 80%;
      @media (min-width: 900px) {
        max-width: 600px;
      }
    }

    .ql-container {
      min-height: 200px;
    }
  }
}
</style>
